<h4 mat-dialog-title>
    {{title}}
</h4>
<div mat-dialog-content>
    <p>{{message}}</p>
</div>
<div mat-dialog-actions class="d-flex justify-content-between">
    <button mat-raised-button cdkFocusInitial (click)="onNoClick()" class="btn btn-secondary">Nein</button>
    <button mat-raised-button [mat-dialog-close]="true" cdkFocusInitial class="btn btn-primary w-50">Ja</button>
</div>
