import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {map, takeUntil} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Subject, Observable, throwError} from 'rxjs';
import {Response} from '../../models/response.model';
import {catchError} from 'rxjs/operators';
import {State} from '../../store/reducers/esare.reducer';

@Injectable({
    providedIn: 'root',
})
export class DelayInfoRestService extends RestServiceAbstract {

    protected ngUnsubscribe$: Subject<void> = new Subject<void>();


    public loadDelays(rq) {

        return this.get(`/resource/admin/tracking?page=${rq.page}&size=${rq.size}&sort=${rq.sort.field},${rq.sort.direction}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    updateDelay(request: any) {
        return this.put(`/resource/admin/tracking`, request).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        );

    }

    createDelay(request: any) {
        return this.post(`/resource/admin/tracking`, request).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        )
    }

    deleteDelay(request: any) {
        return this.delete(`/resource/admin/tracking`,{body: request }).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err);
            })
        )
    }

    getToursOfTheDay(dc: any, date: string) {
        return this.get(`/resource/admin/tracking/tours?dc=${dc}&date=${date}`).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err)
            })
        )
    }

    getTourIdfs(dc: any, date: string, tours: string) {
        return this.get(`/resource/admin/tracking/tours-idf?distributionCenter=${dc}&date=${date}&tours=${tours}` ).pipe(
            takeUntil(this.ngUnsubscribe$),
            map(data => {
                return new Response(data);
            }),
            catchError((err) => {
                return throwError(err)
            })
        )
    }
}
