export class PharmacyUserPaginationModel {
    size: number;
    page: number;
    s: String;

    constructor(obj: any = {}) {
        this.size = obj && obj.size || 10;
        this.page = obj && obj.page || 0;
        this.s = obj && obj.s || '';
    }

    setSearch(obj: any = {}) {
        this.s = obj && obj.s || '';
        return this;
    }
}
